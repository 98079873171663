
.input-checkbox~label:not(.error):after,
.input-radio~label:not(.error):after {
    background: #a66cff;
}

a {
    color: #000;
}

.toggle-line {
    background: #fff;
}

.card-token {
    background-image: linear-gradient(45deg, #a66cff 0%, #7668fe 100%);
    color: #fff;
}

.btn-primary:disabled,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background: #a66cff;
    border-color: #a66cff;
    color: #fff;
}

.footer-links li a:hover {
    color: #fff;
}

.topbar {
    background: #fff;
}

.user-thumb {
    color: #fff;
    background: #a66cff;
}

.navbar-menu>li>a {
    color: #fff;
}

.progress-percent:after,
.pay-check:checked~label:before,
.pay-check:checked~label {
    border-color: #a66cff;
}

.text-primary {
    color: #fff !important;
}

.navbar-menu>li>a:before,
.bonus-base,
.pay-check-label:after {
    background: #a66cff;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span,
p span {
    color: #FFE234 !Important;
}

.card-title {
    color: #fff;
}

.spinner .sp {
    background-color: #a66cff;
}

.total-amount-lead {
    color: #FFE234;
}

#Floader {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, .9);
    width: 100%;
    height: 100%;
    font-size: 25px;
    text-align: center;
    color: #fff;
    text-shadow: 0 0 0px #000;
    z-index: 9999;
}

.inline-loader {
    width: 30%;
    padding: 25px;
}

.inline-loader .spinner.spiner_img {
    margin: 0 auto !important;
    top: auto;
    left: auto;
    position: initial;
}

.inline-loader span {
    font-size: 20px;
    margin: 0 auto;
}

.user-dashboard.page-user .pay-wallet-address button.copy-trigger.copy-clipboard {
    min-width: 160px !important;
}

.user-thumb:hover,
.user-thumb:focus {
    color: #000;
}

.copy-feedback {
    color: #000;
    height: auto;
    text-align: right;
    padding: 13px 20px;
}

.copy-trigger:focus,
.copy-trigger:hover,
.input-switch:checked~label:before {
    background: #a66cff;
}

.data-head .data-col,
.total-amount-lg {
    color: #000;
}

.navbar-menu>li>a:hover,
.navbar-menu>li>a:focus,
.navbar-menu>li>a:active,
.nav-tabs-line .nav-link.active {
    color: #CC89FB;
}

.user-status,
.nav-tabs-line .nav-link:after {
    background: #a66cff;
}

.user-dropdown:after {
    border-bottom-color: #a66cff;
}

h1,
h2,
h4,
h5 {
    color: #fff;
}

ul.radio_ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.radio_ul li {
    border: 1px solid #fff;
    border-radius: 5px;
    width: calc(50% - 10px);
    padding: 10px 15px 10px 40px;
    position: relative;
}

.radio_ul li:after {
    content: '';
    position: absolute;
    background: var(--bg-icon);
    height: 22px;
    width: 22px;
    left: 8px;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
}

.radio_ul input[type="radio"] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
}

.radio_ul input[type="radio"]:checked::before {
    content: '';
    position: absolute;
    background: #a66cff47;
    width: 100%;
    height: 100%;
    visibility: visible;
}

.radio_ul li.active {
    border-color: #a66cff;
}

.radio_ul li.active::before {
    position: absolute;
    content: '';
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background: #7968fe;
    left: auto;
    right: -9px;
    top: -1px;
    border: 4px solid #fff;
    transform: translateY(-50%);
}

.progress-percent {
    border-radius: 10px;
    background: linear-gradient(268deg, #00A3FF 12.45%, #D49CFB 77.44%);
}

.token-overview-value {
    color: #fff;
}

.copy-address {
    padding: 12px 20px;
}

.user-account-status .btn-grp li a {
    cursor: default !important;
}

.page-ath .input-checkbox~label:not(.error):after,
.page-ath .input-radio~label:not(.error):after {
    background: #fff;
}

a,
.page-ath-heading small,
.input-checkbox-md~label:not(.error),
.input-radio-md~label:not(.error),
.form-note,
.footer-links li,
.input-item label,
label,
.btn-primary,
.btn,
body p,
div,
span {
    font-family: 'IBM Plex Mono', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
    font-family: "open_sauce_sans", sans-serif
}

.footer-links li a,
.page-ath-heading,
.page-ath a,
.input-checkbox~label:not(.error),
.input-radio~label:not(.error),
.page-ath-heading small,
.form-note,
.footer-links li,
.page-ath .btn-primary,
.page-ath .btn-primary,
.input-item label,
label,
.input-bordered,
.select2-container--flat .select2-selection--single .select2-selection__rendered,
.select2-container--flat .select2-selection--multiple .select2-selection__rendered,
.input-bordered:focus,
.btn-outline.btn-light:hover,
.btn-outline.btn-light:focus,
.btn-outline.btn-light:active,
.btn-outline.btn-light,
.user-welcome,
body p,
div,
span {
    color: #fff;
}

.page-ath .btn-primary,
.btn-primary,
.btn,
.btn-outline.btn-light,
.btn-danger {
    border: 1px solid #000;
    color: #000;
    border-radius: 8px;
    background: #CC89FB;
    box-shadow: 4px 4px 0px #000;
}

.page-ath .btn-primary {
    min-height: 50px;
}

.footer-links li a:is(:hover, :focus, :active),
.page-ath a:is(:hover, :focus, :active),
.link,
.navbar-menu>li.active a {
    color: #CC89FB;
    font-weight: 900;
}

.page-ath .btn-primary:disabled,
.page-ath .btn-primary:hover,
.page-ath .btn-primary:focus,
.page-ath .btn-primary:active,
.page-ath .input-checkbox~label:not(.error):after,
.page-ath .input-radio~label:not(.error):after,
.btn:is(:hover, :focus, :disabled, :active),
.btn-primary:is(:hover, :focus, :disabled, :active),
.btn-outline.btn-light:hover,
.btn-outline.btn-light:focus,
.btn-outline.btn-light:active {
    background: #a66cff;
    border-color: #a66cff;
    color: #fff;
}

.input-bordered,
.select2-container--flat .select2-selection--single .select2-selection__rendered,
.select2-container--flat .select2-selection--multiple .select2-selection__rendered,
.select2-container .select2-selection--single,
.select2-selection--single .select2-selection__arrow,
.select2-selection--multiple .select2-selection__arrow,
body .select-block~.select2-container.select2,
.select-bordered~.select2-container--flat.select2-container--open .select2-selection--single,
.select-bordered~.select2-container--flat.select2-container--open .select2-selection--multiple {
    min-height: 50px;
    border-radius: 8px;
}

.select2-container--flat .select2-selection--single .select2-selection__rendered {
    height: auto;
    display: flex;
    align-items: center;
}

.input-bordered,
.select-bordered~.select2-container--flat .select2-selection--single,
.select-bordered~.select2-container--flat .select2-selection--multiple,
.input-checkbox~label:not(.error):before,
.input-radio~label:not(.error):before,
body .deg_current_option,
.input-bordered:focus,
body .select-block~.select2-container.select2,
.select-bordered~.select2-container--flat.select2-container--open .select2-selection--single,
.select-bordered~.select2-container--flat.select2-container--open .select2-selection--multiple {
    border-color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid 000;
    -webkit-text-fill-color: 000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

body .select-block~.select2-container.select2 {
    border: 0;
}

body .select2-container {
    z-index: 9999;
}

body .deg_select_box {
    z-index: 1000;
}

.aside.sidebar-right .ti-arrow-right {
    display: none;
}

.token-pay-currency {
    top: 13px;
}

.toast-message {
    color: #fff;
}

body .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
}

body .validate-modern select ~ .error {
    z-index: 99999;
}
.input-bordered, .select2-container--flat .select2-selection--single .select2-selection__rendered, .select2-container--flat .select2-selection--multiple .select2-selection__rendered, .select2-container .select2-selection--single, .select2-selection--single .select2-selection__arrow, .select2-selection--multiple .select2-selection__arrow, body .select-block~.select2-container.select2, .select-bordered~.select2-container--flat.select2-container--open .select2-selection--single, .select-bordered~.select2-container--flat.select2-container--open .select2-selection--multiple
span#nationality-error {
    z-index: 11;
}
span.select2.select2-container.select2-container--flat.bordered {
    z-index: 0;
}
/*9/1/2024*/

/*.page-ath-alt .page-ath-content {
    
    background-image: url(/public/images/sign-in.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}*/
h2.page-ath-heading {
    color: #fff;
    text-align: center;
}
.input-bordered, .footer-links li a, .footer-links li {
    color: #fff !important;
}
.input-bordered {
    border-radius: 4px;
    border: 1px solid #332165;
    width: 100%;
    padding: 10px 15px;
    line-height: 20px;
    font-size: .9em;
    color: rgba(73,84,99,0.7);
    transition: all .4s;
    background: #2A1A54;
}
.page-ath-alt .page-ath-form {
    background: linear-gradient(29deg,#643eba66 38.88%,#d49cfb66 122.18%);
    border-radius: 6px;
    padding: 35px 40px;
    box-shadow: 0 0 25px 0 rgba(0,0,0,0.05);
}
::placeholder {
    color: #fff !important;
}
.page-user {
    /* background: url(/public/images/Dashboard.svg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #332165;
}
.card-innr {
    position: relative;
    padding: 20px 25px;

}
.card {
    margin-bottom: 15px;
    transition: background .4s, border-color .4s, color .4s;
    vertical-align: top;
    background: linear-gradient(rgba(100, 62, 186, 1), rgba(212, 156, 251, 1));
    border-radius: 10px;
    border: 1px solid #643EBA;
    background: linear-gradient(29deg, rgba(100, 62, 186, 0.40) 38.88%, rgba(212, 156, 251, 0.40) 122.18%);
    box-shadow: 0px 2.767px 2.214px 0px rgba(36, 7, 63, 0.02), 0px 6.65px 5.32px 0px rgba(36, 7, 63, 0.03), 0px 12.522px 10.017px 0px rgba(36, 7, 63, 0.04), 0px 22.336px 17.869px 0px rgba(36, 7, 63, 0.04), 0px 41.778px 33.422px 0px rgba(36, 7, 63, 0.05), 0px 100px 80px 0px rgba(36, 7, 63, 0.07);
    backdrop-filter: blur(25px);
    
}
.progress-info li span, .progress-info li, .card-sub-title, .token-bonus-date, 
.form-control, .input-switch ~ label:not(.error), .total-title, 
.total-title-sm, .total-title-xs, .total-title-lead,
 div.dataTables_wrapper div.dataTables_info, .nav-tabs-line .nav-link
.total-note{
    color: #fff;
}

.text-dark, .text-exlight, .copyright-text, .text-light {
    color: #fff !important;
}

.topbar, .navbar, .topbar-wrap {
    border-bottom: none;
    background: transparent !Important;
}
a:hover, a:focus, a:active {
    outline: 0;
    color: #FFE234;
}
.card-full-height {
    height: calc(100% - 15px);
}
.data-table:not(.dt-init) .data-item:last-child .data-col {
    padding-bottom: 14px;
}
.data-head .data-col {
    padding: 10px 10px 0 10px;
}
.data-table th.dataTables_empty, .data-table td.dataTables_empty {
    border-radius: 0 !important;
}
.content_right.page-content11 .row {
    margin-right: 0;
    margin-left: 0;
}
.total-note span {
    color: #FFE234;
}
.total-amount, .total-amount-lead, .total-amount-lg, .total-amount-md, .total-amount-sm {
    display: block;
    margin-bottom: 0;
    line-height: 1.3;
    color: #FFE234;
}
.token-pay-amount.payment-get input#amountInUSD {
    padding: 10px 45px 10px 15px;
}
.topbar.is-sticky {
    background: #644a9a !important;
}
.topbar-logo {
    padding: 8px 0;
}
.navbar.navbar-mobile {
    background: #644a9a !important;
}
.dropdown-content {
    background: #7357a0;
}
.dropdown-content-top-left:after {
    border-left: 7px solid #7357a0;
}
.user-dashboard.page-user .pay-wallet-address {
    background-color: #644a9a !important;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: #a66cff;
    border-color: #a66cff;
}
.badge-success {
    color: #fff !important;
    background: #00d285;
}
.progress-percent:after {
    position: absolute;
    content: '';
    right: 0;
    top: 50%;
    height: 37px;
    width: 47px;
    /* border: 3px solid #7668fe; */
    border: none;
    /*background: #fff;*/
    border-radius: 50%;
    transform: translate(50%, -50%);
    /* background: url(/public/images/kang-progress-icon.png); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.progress-bar, .progress-hcap, .progress-scap, .progress-psale, .progress-percent {
    height: 12px;
}
.progress-bar {
    border-radius: 10px;
}
h5.token-amount.pay-amount-u.amount-crypto, h5.dgrn-amount.token-amount.pay-amount-u {
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-outter .data-col:last-child {
    padding-right: 10px;
}
.table-outter .data-col {
    padding: 14px 7px 14px 7px;
    vertical-align: top;
}
.data-item .lead {
    font-size: 12px;
}
.form-control:focus {
    color: #fff;
}
.table-outter .dataTables_filter label {
    background: #cc89fb5c;
    border-radius: 10px;
}
.table-outter .btn span {
    color: #000;
}
.table-outter .btn:hover span {
    color: #fff;
}
.modal-content {
    background-color: #644a9a;
}
.popup-title {
    color: #fff;
}
table.dataTable th>span {
    color: #000;
}

.token-pay-currency .input-hint {
    padding: 5px 2px;
}
.token-purchase .radio_ul li:after {
    top: 23px;
}
#add-wallet input#token-address {
    min-height: auto;
}
#Floader .inline-loader {
    width: 95%;
    max-width: 700px;
}
.toggle-tigger.dt-filter-text em {
    line-height: 1.2;
}
.copy-feedback {
    padding-right: 45px;
}
.user-receive-wallet .d-flex.justify-content-between {
    font-size: 12px;
}
.copy-address {
    padding-left: 35px;
    padding-right: 45px;
}
form.forgot-pass-form .d-flex.justify-content-between.align-items-center {
    gap: 20px;
}
input#google2fa_code::-webkit-outer-spin-button,
input#google2fa_code::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
form.validate-modern a {
    box-shadow: none;
}
.topbar-nav li.active a {
    color: #a66cff;
}

table.refferal-table td {
    font-size: 14px;
    color: #000;
}
table.refferal-table td.data-col.refferal-name {
    word-break: break-all;
  min-width: 120px;
}
table.refferal-table td.data-col.refferal-date {
    min-width: 180px;
}

table.refferal-table td.data-col.refferal-tokens {
    min-width: 120px;
}
.data-col {
    padding: 14px 15px 14px 5px;
}
.data-col:last-child {
    padding-right: 6px;
}
.text-head {
    color: #FFE969 !important;
}
p a {
    color: #FFE969;
}
p a:hover, p a:focus {
    color: #cc89fb;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.input-note {
    color: #f7f6fa;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
span.loader_text {
    color: #000;
}
.dropdown-content-center-left:after {
    border-left: 7px solid #7357a0;
}
table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
    color: #644a9a;
}
.nav-tabs-line .nav-link {
    color: #fff;
}
.user-welcome {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
}
.bg-light {
    background: #7357a0 !important;
}
.topbar-nav-item .dropdown-content a {
    color: #fff;
}
.topbar-nav-item .dropdown-content a:hover {
    color: #FFE234;
}
.token-overview-title {
    font-size: 10px;
    font-weight: 400;
}
/* Media Query */
@media (min-width: 992px) {
    .topbar-logo img {
        height: 50px;
    }
    .navbar-menu>li {
        padding: 0 15px;
    }
}
@media(max-width: 992px) {
    .account-info .user-account-status .card-title, .account-info .user-receive-wallet .card-title {
        font-size: 1.2em;
    }
    .topbar-logo img {
        height: 50px;
    }
}

@media(max-width: 767.98px) {
    .radio_ul li {
        width: 100%;
    }

    .token-bonus,
    .token-total {
        padding: 20px 0 0;
    }

    .token-overview .col-md-4:last-child .token-bonus,
    .token-overview .col-md-4:last-child .token-total {
        padding: 20px 0;
    }

    .card-innr h3 .ucap {
        margin: 6px 0 0;
    }

    #Floader .inline-loader {
        width: 100%;
    }
}

@media(max-width: 480px) {
    .page-ath-alt .page-ath-form {
        width: 95%;
        padding: 20px;
    }
    
    table.refferal-table {
        width: 440px;
        font-size: 12px;
    }
}
@media (max-width: 390px) {
    .card-innr {
        padding: 20px 15px;
    }
}


