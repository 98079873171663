@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Zen+Kaku+Gothic+Antique&display=swap');


body {
    background-color: rgba(11, 12, 19, 1) !important;
    
}

* {
    font-family: "Lato", sans-serif !important;
}

select option {
    background-color: #161A28;
    color: #9D9D9D;
}

.swal2-container.swal2-center>.swal2-popup {
    background-color: #292E3E;
    color: #fff;
    font-family: "Lato", sans-serif !important;
}

.swal2-styled.swal2-confirm {
    background: linear-gradient(253.57deg, #EF9933 -17.46%, #D7913F 36.74%, #C96D00 99.84%) !important;
}